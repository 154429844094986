












































































































import { Component, Vue } from 'vue-property-decorator';
import { currencyFormat } from '@/vue-app/utils/currency';

import FlagshipProfilingProjectionViewModel
  from '@/vue-app/view-models/components/flagship/flagship-profiling/flagship-profiling-projection-view-model';

@Component({
  name: 'FlagshipProfilingProjection',
  components: {
    CustomTooltip: () => import('@/vue-app/components/custom/CustomTooltip.vue'),
  },
  filters: { currencyFormat },
})
export default class FlagshipProfilingProjection extends Vue {
  flagship_projection_view_model = Vue.observable(
    new FlagshipProfilingProjectionViewModel(this),
  );

  created() {
    this.flagship_projection_view_model.initialize();
  }
}

import TYPES from '@/types';
import i18n from '@/vue-app/plugins/i18n';
import Vue from 'vue';
import { currencyFormat } from '@/vue-app/utils/currency';

// Application
import GetRetirementForecastCalculationQuery
  from '@/modules/flagship/retirement-forecast-calculation/application/queries/get-retirement-forecast-calculation-query';
import RetirementInvestorGoalCommand from '@/modules/flagship/retirement-investor-goal/application/commands/retirement-investor-goal-command';
import GetRecommendedInvestmentProductQuery
  from '@/modules/flagship/investor-profile/recommended-investment-product/application/queries/get-recommended-investment-product-query';
import UpdateInvestorProfileOnFlowCompleteCommand
  from '@/modules/flagship/investor-profile/flow-completed/application/commands/update-investor-profile-on-flow-complete-command';
import CreateRetirementForecastCalculationCommand
  from '@/modules/flagship/retirement-forecast-calculation/application/commands/create-retirement-forecast-calculation-command';
import GetInvestorProfileQuery
  from '@/modules/flagship/investor-profile/investor-profile/application/queries/get-investor-profile-query';
import GetRetirementInvestorGoalQuery
  from '@/modules/flagship/retirement-investor-goal/application/queries/get-retirement-investor-goal-query';
import {
  GetHasKuspitAccountQuery,
} from '@/modules/my-investment/kuspit/customer-has-account/application/queries';
import {
  GetAllianzPolicyIssuanceStatusQuery,
} from '@/modules/my-investment/allianz/allianz-policy-issuance-status/application/queries';

// Domain
import {
  RetirementForecastCalculationDto,
} from '@/modules/flagship/retirement-forecast-calculation/domain/dtos/retirement-forecast-calculation-dto';
import {
  InvestorProfileEntity,
} from '@/modules/flagship/investor-profile/investor-profile/domain/entities/investor-profile-entity';
import {
  ForecastCalculationDto,
} from '@/modules/flagship/retirement-forecast-calculation/domain/dtos/forecast-calculation-dto';
import { RetirementInvestorGoalEntity } from '@/modules/flagship/retirement-investor-goal/domain/entities/retirement-investor-goal-entity';
import Inject from '@/modules/shared/domain/di/inject';
import { MessageNotifier } from '@/modules/shared/domain/notifiers/message_notifier';

export default class FlagshipProfilingProjectionViewModel {
  @Inject(TYPES.RETIREMENT_INVESTOR_GOAL_COMMAND)
  private readonly create_retirement_invest_command!: RetirementInvestorGoalCommand;

  @Inject(TYPES.UPDATE_INVESTOR_PROFILE_ON_FLOW_COMPLETE_COMMAND)
  private readonly update_flow_complete_command!: UpdateInvestorProfileOnFlowCompleteCommand;

  @Inject(TYPES.GET_RETIREMENT_FORECAST_CALCULATION_QUERY)
  // eslint-disable-next-line max-len
  private readonly get_forecast_calculation_query!: GetRetirementForecastCalculationQuery;

  @Inject(TYPES.GET_RECOMMENDED_INVESTMENT_PRODUCT_QUERY)
  private readonly get_recommended_product_query!: GetRecommendedInvestmentProductQuery;

  @Inject(TYPES.CREATE_RETIREMENT_FORECAST_CALCULATION_COMMAND)
  private readonly create_retirement_command!: CreateRetirementForecastCalculationCommand;

  @Inject(TYPES.GET_INVESTOR_PROFILE_QUERY)
  private readonly get_investor_profile_query!: GetInvestorProfileQuery;

  @Inject(TYPES.GET_RETIREMENT_INVESTOR_GOAL_QUERY)
  private readonly get_retirement_investor_query!: GetRetirementInvestorGoalQuery;

  @Inject(TYPES.GET_CUSTOMER_HAS_KUSPIT_ACCOUNT_QUERY)
  private readonly get_has_kuspit_account_query!: GetHasKuspitAccountQuery;

  @Inject(TYPES.GET_ALLIANZ_POLICY_ISSUANCE_STATUS_QUERY)
  private readonly get_allianz_policy_issuance_status_query!: GetAllianzPolicyIssuanceStatusQuery;

  @Inject(TYPES.NOTIFIER)
  private readonly message_notifier!: MessageNotifier;

  private readonly view!: Vue;

  readonly i18n_namespace = 'components.flagship.flagship-profiling.flagship_profiling_projection';

  private readonly customer_id = sessionStorage.getItem('user_id');

  private readonly error_allianz_policy_status_not_exists = `Error allianz_policy_issuance_status_entity for customer_id_value:${this.customer_id}, not exists`;

  SLIDER_MAX_VALUE = 75;

  SLIDER_MIN_VALUE = 55;

  is_poket = false;

  is_wealth= false;

  you_already_have_your_contracted_product = false;

  is_loading = false;

  has_pocket = false;

  has_wealth = false;

  monthly_payment = '';

  retirement_years = 0;

  accumulated_amount = '';

  monthly_pension_amount = '';

  initial_amount = '';

  monthly_confirmed_amount = '';

  chart_series: Array<any> = [];

  investor_profile_id = '';

  investor_goal_id = '';

  retirement_investor_id = '';

  issued_age = 0;

  chart_options = {
    chart: {
      type: 'area',
      height: 350,
      stacked: true,
      parentHeightOffset: 0,
      toolbar: {
        show: false,
      },
      zoom: {
        enabled: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'smooth',
    },
    yaxis: {
      labels: {
        formatter(value: number) {
          return currencyFormat(value);
        },
      },
    },
    tooltip: {
      fixed: {
        enabled: false,
        position: 'topRight',
      },
    },
  };

  private investor_profile_entity: InvestorProfileEntity = {
    id: '',
    customer_id: sessionStorage.getItem('user_id')!,
    custom_goals: {},
    is_showed: false,
    person_id: '',
    employment_situation_id: '',
    recommended_investment_product_id: '',
    emergency_fund_availability_id: '',
    has_emergency_fund: true,
    net_monthly_expenses: 0,
    elder_dependent_count: 0,
    is_completed: true,
    child_dependent_count: 0,
    pet_dependent_count: 0,
    net_monthly_income: 0,
    active_goals_count: 0,
    goals_completed: false,
    emergency_fund_locations: {},
    retirement_fund_locations: {},
    exit_pool: {},
    finance_related_job: false,
    income_behavior_in_following_year_id: '',
    emergency_fund_amount: 0,
    has_retirement_fund: null,
  }

  private forecast_calculation: ForecastCalculationDto = {
    retirement_chart_report: {},
    retirement_calculation_data: {
      retirement_range_adjusted: 0,
      monthly_required_amount: 0,
      pension_range_adjusted: 0,
      interest_rate: 0,
      initial_amount: 0,
      accumulated_amount: 0,
      monthly_pension_adjusted: 0,
    },
    investor_profile_data: {
      initial_amount: 0,
      investment_goal_type_id: '',
      issued_age: 0,
      interest_rate: '',
      recommended_product_id: '',
      customer_id: '',
      lifetime_expected: 0,
      investor_profile_id: '',
      retirement_age_adjusted: 0,
      investor_goal_id: '',
      monthly_required_amount: 0,
    },
    retirement_forecast: {},
  }

  public constructor(view: Vue) {
    this.view = view;
  }

  translate = (value: string, options?: Record<string, string | number>) => {
    if (options) {
      return i18n.tc(`${this.i18n_namespace}.${value}`, 0, options) as string;
    }
    return i18n.t(`${this.i18n_namespace}.${value}`) as string;
  };

  initialize = async () => {
    await this.getInvestorProfile();
    await this.getRetirementInvestor();
    await this.getRetirementForecastCalculation();
    await this.verifyIfCustomerHasKuspitAccount();
    await this.verifyIfCustomerHasAllianzAccount();
  }

  get amount_currency_format() {
    return Math.trunc(Number(this.accumulated_amount)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  }

  getInvestorProfile = async () => {
    try {
      const investor_profile = await this.get_investor_profile_query.execute();
      this.investor_profile_id = investor_profile.id;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_investor_profile'));
    }
  };

  verifyIfCustomerHasKuspitAccount = async () => {
    try {
      const kuspit_account = await this.get_has_kuspit_account_query.execute();
      this.has_pocket = kuspit_account.has_account;
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_has_kuspit_account'));
    }
  }

  verifyIfCustomerHasAllianzAccount = async () => {
    try {
      await this.get_allianz_policy_issuance_status_query.execute({
        customer_id: this.customer_id,
      });
      this.has_wealth = true;
    } catch (error) {
      if (error.message !== this.error_allianz_policy_status_not_exists) {
        this.message_notifier.showErrorNotification('Ha ocurrido un error, inténtelo nuevamente');
      }
    }
  }

  getRetirementInvestor = async () => {
    try {
      const retirement_investor = await this.get_retirement_investor_query.execute(true);
      this.retirement_investor_id = retirement_investor.id;
      this.investor_goal_id = retirement_investor.investor_goal_id;
      this.initial_amount = retirement_investor.investor_goal.initial_amount?.toString() || '0.00';
    } catch (error) {
      if (JSON.parse(error).status_code !== 404) {
        this.message_notifier.showErrorNotification(this.translate('errors.get_retirement_investor'));
      }
    }
  };

  showRecommendedProduct = async (is_wealth: boolean, is_pocket: boolean) => {
    let recommended_investment_product = '';
    if ((!this.has_wealth && (is_wealth || (is_pocket && this.has_pocket)))) {
      recommended_investment_product = 'wealth';
    }
    if (!this.has_pocket && (is_pocket || (is_wealth && this.has_wealth))) {
      recommended_investment_product = 'pocket';
    }
    if (this.has_pocket && this.has_wealth) {
      recommended_investment_product = 'any';
    }
    this.view.$emit('closeModal', recommended_investment_product);
  }

  getRecommendedInvestmentProduct = async () => {
    try {
      // eslint-disable-next-line max-len
      const { investment_product } = await this.get_recommended_product_query.execute(this.investor_profile_id);
      const is_wealth = (investment_product.name === 'sowos_wealth');
      const is_pocket = (investment_product.name === 'sowos_pocket');
      await this.showRecommendedProduct(is_wealth, is_pocket);
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_recommended_investment_product'));
    }
  }

  getRetirementForecastCalculation = async () => {
    try {
      this.forecast_calculation = await this.get_forecast_calculation_query.execute();
      this.projectGraph(this.forecast_calculation);
      this.updateProjection(this.forecast_calculation);
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.get_retirement_forecast_calculation'));
    }
  };

  updateProjection = (forecast_calculation: ForecastCalculationDto) => {
    // eslint-disable-next-line max-len
    this.monthly_payment = Math.trunc(forecast_calculation.retirement_calculation_data.monthly_required_amount).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    this.retirement_years = forecast_calculation.investor_profile_data.retirement_age_adjusted;
    this.issued_age = forecast_calculation.investor_profile_data.issued_age;
    this.SLIDER_MIN_VALUE = this.issued_age > this.SLIDER_MIN_VALUE
      ? this.issued_age + 1 : this.SLIDER_MIN_VALUE;
    this.accumulated_amount = forecast_calculation.retirement_calculation_data.accumulated_amount
      .toString();
    this.monthly_pension_amount = Math.trunc(
      forecast_calculation.retirement_calculation_data.monthly_pension_adjusted,
    ).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    this.monthly_confirmed_amount = forecast_calculation
      .retirement_calculation_data.monthly_required_amount.toString();
  }

  projectGraph = (series: ForecastCalculationDto) => {
    const retirement_chart_report: Array<any> = [];
    this.chart_series = [];

    Object.entries(series.retirement_chart_report).forEach((serie: any) => {
      retirement_chart_report.push({
        x: (serie[0] === 0) ? `${i18n.tc(`${this.i18n_namespace}.today`)}`
          : `${serie[0]} ${i18n.tc(`${this.i18n_namespace}.years`)}`,
        y: serie[1],
      });
    });

    this.chart_series.push({
      name: `${i18n.tc(`${this.i18n_namespace}.monthly_savings`)}`,
      data: retirement_chart_report,
    });
  }

  updateInformation = async () => {
    try {
      this.is_loading = true;

      const retirement_investor_goal_entity: RetirementInvestorGoalEntity = {
        id: this.retirement_investor_id,
        retirement_age_adjusted: this.retirement_years,
        issued_age: this.forecast_calculation.investor_profile_data.issued_age,
        // eslint-disable-next-line max-len
        retirement_range_adjusted: String(this.forecast_calculation.retirement_calculation_data.retirement_range_adjusted),
        // eslint-disable-next-line max-len
        pension_range_adjusted: this.forecast_calculation.retirement_calculation_data.pension_range_adjusted,
        // eslint-disable-next-line max-len
        monthly_pension_adjusted: String(this.forecast_calculation.retirement_calculation_data.monthly_pension_adjusted),
        investor_goal: {
          investor_profile_id: this.investor_profile_id,
          initial_amount: Number(parseFloat(this.initial_amount.replaceAll(/[^\d.-]/g, '')).toFixed(2)),
          accumulated_amount: Number(parseFloat(this.accumulated_amount.replaceAll(/[^\d.-]/g, '')).toFixed(2)),
          monthly_required_amount: parseFloat(this.monthly_confirmed_amount.replaceAll(/[^\d.-]/g, '')).toFixed(2),
        },
      };

      await this.create_retirement_invest_command.execute(retirement_investor_goal_entity);

      await this.getRetirementInvestor();
      await this.getRecommendedInvestmentProduct();
      await this.updateFlowComplete();
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.create_retirement_investor_goal'));
    } finally {
      this.is_loading = false;
    }
  }

  updateFlowComplete = async () => {
    try {
      this.investor_profile_entity.id = this.investor_profile_id;
      this.investor_profile_entity.is_completed = true;
      await this.update_flow_complete_command.execute(this.investor_profile_entity);
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.update_flow_complete'));
    }
  }

  createInformationCalculation = async () => {
    try {
      this.is_loading = true;

      const retirement_forecast_dto: RetirementForecastCalculationDto = {
        investor_goal_id: this.investor_goal_id,
        initial_amount: Number(parseFloat(this.initial_amount.replaceAll(/[^\d.-]/g, '')).toFixed(2)),
        monthly_required_amount: Number(parseFloat(this.monthly_confirmed_amount.replaceAll(/[^\d.-]/g, '')).toFixed(2)),
        retirement_age_adjusted: this.retirement_years,
      };

      // eslint-disable-next-line max-len
      this.forecast_calculation = await this.create_retirement_command.execute(retirement_forecast_dto) as ForecastCalculationDto;
      this.projectGraph(this.forecast_calculation);
      this.updateProjection(this.forecast_calculation);
    } catch {
      this.message_notifier.showErrorNotification(this.translate('errors.create_retirement_forecast_calculation'));
    } finally {
      this.is_loading = false;
    }
  }

  prevStep = () => {
    this.view.$emit('prevStep');
    this.view.$emit('prevStep');
  }
}
